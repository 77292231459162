"use client";

import { type ComponentProps } from "react";
import styles from "./video.module.css";
import MuxPlayer from "@mux/mux-player-react";
import { cx } from "@tracksuit/utils";

export type VideoProps = {
  videoId: string;
  thumbnail?: string;
} & ComponentProps<"div">;

/**
 * @component
 * Video component
 */
export function Video({ videoId, thumbnail, className, ...props }: VideoProps) {
  return (
    <div className={cx(className, styles.video)}>
      {videoId && <MuxPlayer playbackId={videoId} poster={thumbnail} />}
    </div>
  );
}
