"use client";
import Logo from "$src/assets/img/logo.svg";
import { useHeader } from "$src/stores/useHeader";
import { cx } from "@tracksuit/utils";
import Link from "next/link";
import { Button } from "../button/button";
import { NavLink } from "../nav-link/nav-link";
import styles from "./header.module.css";
import { MobileHeader } from "./lib/mobile-header/mobile-header";
import { HeaderNav } from "./lib/nav";

const INVERTED_HEADER_PATHS = ["/careers"];

export const ClientHeader = ({
  region,
  headerNavData
}: {
  region: string;
  headerNavData: any;
}) => {
  const inverted = useHeader((s) => s.inverted);

  return (
    <div className={styles.header}>
      <Link
        href={`/${region}`}
        className={styles["logo-link"]}
        aria-label="Tracksuit"
      >
        <span className="sr-only">Tracksuit</span>
        <Logo
          className={cx(styles.logo, inverted && styles["invert-header"])}
          data-testid={"logo"}
        />
      </Link>
      <HeaderNav
        invertText={inverted}
        headerNavData={headerNavData}
        data-testid={"links"}
        region={region}
      />
      <div className={styles.container}>
        <NavLink
          href={"https://app.gotracksuit.com/login"}
          className={cx(styles.login, inverted && styles["invert-header"])}
        >
          Login
        </NavLink>
        <Button
          className={cx(styles.button, styles.focus)}
          label={"Request a demo"}
          href={`/${region}/contact`}
        />
      </div>
      <MobileHeader
        invertHeader={inverted}
        region={region}
        data={headerNavData}
        className={cx(styles["mobile-menu"])}
      />
    </div>
  );
};
