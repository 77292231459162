"use client";
import { cx } from "@tracksuit/utils";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import styles from "./nav-link.module.css";

type NavLinkProps = {
  activeClass?: string | false;
  className?: string;
  children: ReactNode;
  includeSearchParamsInActiveStateCheck?: boolean;
} & LinkProps;

/**
 * @component
 *
 */
export function NavLink({
  className,
  activeClass,
  includeSearchParamsInActiveStateCheck = false,
  children,
  href,
  ...props
}: NavLinkProps) {
  const pathname = usePathname();

  return (
    <Link
      href={href ? href : ""}
      className={cx(className, styles.link, href === pathname && activeClass)}
      {...props}
    >
      {children}
    </Link>
  );
}
