"use client";

import { useKeyboardEvent, useClickOutside } from "@react-hookz/web";
import { cx } from "@tracksuit/utils";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import { useRef, useState, type ComponentProps, type ReactNode } from "react";
import styles from "./dropdown.module.css";

export type DropdownProps = {
  /** Label of the dropdown */
  label: ReactNode;
  /** Whether dropdown is open */
  open: boolean;
  /** Whether to be compact mode */
  compact?: boolean;
  /** Whether dropdown is disabled */
  disabled?: boolean;
  /** Whether dropdown menu should be constrained to trigger width */
  fixedWidth?: boolean;
  /** Visual theme of dropdown */
  theme?: "select" | "pill" | "menu" | "unstyled";
  /** Called when dropdown requests open */
  onChange(state: boolean): void;
  /* Choose which side the dropdown displays on */
  align?: "left" | "right" | "center";
  /* Edit styling of tigger label */
  triggerLabelClassName?: string;
} & ComponentProps<"div">;

/**
 * @component
 * Generic dropdown menu
 */
export function ControlledDropdown({
  open,
  disabled,
  label,
  compact,
  fixedWidth,
  theme = "unstyled",
  onChange,
  align = "center",
  triggerLabelClassName,
  className,
  children,
  ...props
}: DropdownProps) {
  const container = useRef(null);

  // These are excluded from coverage because the hooks themselves are well tested
  /* istanbul ignore next */
  useClickOutside(container, () => onChange(false));
  /* istanbul ignore next */
  useKeyboardEvent("keydown", (e) => {
    e.key === "Escape" && onChange(false);
  });

  function toggleDropdown() {
    onChange(!open);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      toggleDropdown();
    }
  }

  return (
    <div
      className={cx(styles.container, className)}
      ref={container}
      data-disabled={disabled}
      {...props}
    >
      <div
        className={cx(
          styles["dropdown-trigger"],
          styles[theme],
          compact && styles.compact,
          disabled && styles.disabled,
          styles.focus
        )}
        role="button"
        aria-label="Open Dropdown"
        tabIndex={0}
        onClick={() => !disabled && toggleDropdown()}
        onKeyDown={handleKeyPress}
      >
        <div className={cx(styles["trigger-label"], triggerLabelClassName)}>
          {label}
        </div>
        {!disabled && <ChevronDown className={cx(styles["trigger-icon"])} />}
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            data-testid="menu"
            className={cx(
              styles["dropdown-menu"],
              fixedWidth && styles.fixed,
              align === "left" && styles.openLeft,
              align === "right" && styles.openRight
            )}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export function Dropdown({
  children,
  ...props
}: Omit<DropdownProps, "open" | "onChange">) {
  const [open, setOpen] = useState(false);
  return (
    <ControlledDropdown open={open} onChange={setOpen as any} {...props}>
      {children}
    </ControlledDropdown>
  );
}
