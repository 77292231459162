import { cx } from "@tracksuit/utils";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { type ComponentProps } from "react";
import styles from "./button.module.css";

/**
 * @component
 * Different types of buttons: square, primary, secondary, ghost and disabled.
 */
export type ButtonProps = {
  label: string;
  icon?: any;
  theme?: "primary" | "secondary" | "ghost" | "square";
  disabled?: boolean;
} & ComponentProps<"button"> &
  Partial<LinkProps>;

export function Button({
  label,
  theme = "primary",
  href,
  icon: Icon,
  className,
  ...props
}: ButtonProps) {
  const El = href ? Link : "button";

  return (
    <El
      href={href}
      className={cx(styles.button, styles[theme], className)}
      {...(props as any)}
    >
      {Icon && <Icon className={styles.icon} />}
      {label}
    </El>
  );
}
