import { cx } from "@tracksuit/utils";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import { useState, type ComponentProps, type ReactNode } from "react";
import styles from "./collapsible.module.css";

export type CollapsibleProps = {
  /** Trigger section for the collapsible */
  trigger: ReactNode;
  /** Theme color for the collapsible */
  color?: "purple" | "green";
  /** On open/close callback */
  onChange?(open: boolean): void;
  /** Whether trigger should be full width */
  fullWidth?: boolean;
  /** Whether component is disabled */
  disabled?: boolean;
} & Omit<ComponentProps<"div">, "onChange">;

export type CollapsibleTriggerProps = {
  /** Theme color for the collapsible */
  color?: "purple" | "green";
  /** Whether trigger should be full width */
  fullWidth?: boolean;
  /** Whether component is disabled */
  disabled?: boolean;
  open: boolean;
} & Omit<ComponentProps<"div">, "onChange">;

/**
 * @component
 * Base collapsible element with open control
 */
export function ControlledCollapsible({
  open,
  staggered,
  fast,
  onChange,
  disabled,
  children,
  className
}: {
  open: boolean;
  staggered?: boolean;
  fast?: boolean;
  onChange?(open: boolean): void;
  disabled?: boolean;
} & Omit<ComponentProps<"div">, "onChange">) {
  const duration = fast ? 0.15 : 0.3;

  return (
    <>
      {disabled ? null : (
        <motion.div
          initial={false}
          animate={{
            height: open ? "auto" : "0px",
            opacity: open ? 1 : 0
          }}
          transition={{
            height: { duration },
            opacity: { duration, delay: staggered ? duration - 0.05 : 0 }
          }}
          onAnimationStart={() => onChange?.(open)}
          className={cx(className, styles.container)}
          data-testid="collapsible"
        >
          {children}
        </motion.div>
      )}
    </>
  );
}

/**
 * @component
 * Collapsible section with trigger
 */
export function Collapsible({
  color = "purple",
  trigger,
  onChange,
  fullWidth = false,
  disabled,
  children,
  ...props
}: CollapsibleProps) {
  const [open, setOpen] = useState(false);

  return (
    <div {...props}>
      <CollapsibleTrigger
        disabled={disabled}
        onClick={() => setOpen((o) => !o)}
        open={open}
      >
        {trigger}
      </CollapsibleTrigger>
      <ControlledCollapsible
        disabled={disabled}
        open={open}
        onChange={onChange}
      >
        {children}
      </ControlledCollapsible>
    </div>
  );
}

export function CollapsibleTrigger({
  color = "purple",
  children,
  fullWidth = false,
  disabled,
  open,
  ...props
}: CollapsibleTriggerProps) {
  return (
    <div
      className={cx(styles.trigger, fullWidth && styles.fullwidth)}
      {...props}
    >
      <div className={styles["trigger-content"]}>{children}</div>
      {disabled ? null : (
        <div
          data-testid="collapsible-icon"
          className={cx(styles.toggle, open && styles.open)}
        >
          <ChevronDown className={styles.icon} />
        </div>
      )}
    </div>
  );
}
