"use client";
import { NavLink } from "$src/components/nav-link/nav-link";
import { resolveLink } from "$src/lib/sanity";
import { cx } from "@tracksuit/utils";
import { usePathname } from "next/navigation";
import { useMemo, useState } from "react";
import { ControlledDropdown } from "../../dropdown/dropdown";
import { DropdownItem } from "../../dropdown/lib/dropdown-item/dropdown-item";
import styles from "../header.module.css";

// Header nav Dropdown
export function NavDropdown({
  invertText,
  item,
  region
}: Readonly<{
  invertText: any;
  item: any;
  region: string;
}>) {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();
  const isActiveDropdown = useMemo(() => {
    return (
      item?._type === "dropdown" &&
      item?.links?.some((subItem: any) => {
        return pathname === resolveLink(region, subItem.link);
      })
    );
  }, [pathname, region, item]);

  return (
    <ControlledDropdown
      label={item?.label}
      open={open}
      onChange={setOpen as any}
      className={cx(
        styles["link-hover"],
        invertText && styles["invert-header"],
        isActiveDropdown && styles.active
      )}
    >
      {item?._type === "dropdown" &&
        item?.links?.map((subItem: any) => {
          return (
            <DropdownItem
              key={subItem._key}
              id={subItem.label}
              className={cx(
                styles.link,
                styles["dropdown-item"],
                pathname === resolveLink(region, subItem.link) && styles.active
              )}
              onClick={() => setOpen(false)}
              href={resolveLink(region, subItem.link)}
              label={subItem.label}
            />
          );
        })}
    </ControlledDropdown>
  );
}

// Header nav with links and dropdowns
export function HeaderNav({
  invertText,
  headerNavData,
  region
}: Readonly<{
  invertText: any;
  headerNavData: any;
  region: string;
}>) {
  const pathname = usePathname();
  const headerNavArray = Array.isArray(headerNavData?.headerNav)
    ? headerNavData?.headerNav
    : [headerNavData?.headerNav];

  return (
    <nav className={styles.container}>
      {headerNavArray.map((item: any) => {
        if (item?._type === "dropdown" && item.links) {
          return (
            <NavDropdown
              invertText={invertText}
              key={item._key}
              item={item}
              region={region}
            />
          );
        } else if (item?.link) {
          return (
            <NavLink
              key={item._key}
              className={cx(styles.link, invertText && styles["invert-header"])}
              href={resolveLink(region, item.link)}
              activeClass={
                item.link?.internalLink &&
                pathname?.includes(item.link?.internalLink?.slug) &&
                styles.active
              }
            >
              {item.label}
            </NavLink>
          );
        }
      })}
    </nav>
  );
}
