"use client";
import { resolveLink } from "$src/lib/sanity";
import { cx } from "@tracksuit/utils";
import { X } from "lucide-react";
import Link from "next/link";
import { useEffect, useMemo, type ComponentProps } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import styles from "./announcement-bar.module.css";
import { usePathname } from "next/navigation";

export const announcementState = create<{
  announcement?: string;
  closed: boolean;
  set(data: { announcement?: string; closed?: boolean }): void;
}>()(
  persist(
    (set) => ({
      announcement: undefined,
      closed: false,
      set
    }),
    { name: "announcement" }
  )
);

export type AnnouncementBarProps = {
  /** Region */
  region: string;
  /** Announcement copy */
  copy: string;
  /** Announcement Link */
  link: any;
} & ComponentProps<"div">;

/**
 * @component
 * Global announcements bar
 */
export function AnnouncementBar({
  region,
  copy,
  link,
  className
}: AnnouncementBarProps) {
  const state = announcementState();
  const pathname = usePathname();

  const visible = useMemo(() => {
    if (state.announcement === copy && !state.closed) {
      return true;
    }
  }, [state, copy]);

  useEffect(() => {
    if (state.announcement !== copy) {
      state.set({ announcement: copy });
    }

    // Close automatically if url matches link given
    if (!state.closed && pathname === resolveLink(region, link)) {
      state.set({ closed: true });
    }
  });

  return (
    visible && (
      <Link
        href={resolveLink(region, link)}
        className={cx(styles.bar, className)}
      >
        <span className={styles.copy}>{copy}</span>
        <X
          data-testid="close"
          className={styles.close}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            state.set({ closed: true });
          }}
        />
      </Link>
    )
  );
}
