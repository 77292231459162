import { cx } from "@tracksuit/utils";
import { NavLink } from "../nav-link/nav-link";
import FacebookIcon from "$src/assets/icons/facebook.svg";
import InstagramIcon from "$src/assets/icons/instagram.svg";
import LinkedinIcon from "$src/assets/icons/linkedin.svg";
import MailIcon from "$src/assets/icons/mail.svg";
import styles from "./social-icons.module.css";
import type { ComponentProps } from "react";

type SocialIconProps = {
  strokeLight?: boolean;
  instagramLink: string;
  facebookLink: string;
  mailLink: string;
  linkedInLink: string;
} & ComponentProps<"div">;

/**
 * @component
 * Social media icon links
 */
export function SocialIcons({
  strokeLight = false,
  instagramLink,
  facebookLink,
  mailLink,
  linkedInLink,
  className,
  ...props
}: SocialIconProps) {
  return (
    <div className={cx(styles["social-icons"], className)} {...props}>
      <NavLink href={linkedInLink} aria-label="Tracksuit on Linkedin">
        <LinkedinIcon className={styles.social} />
      </NavLink>
      <NavLink href={mailLink} aria-label="Email Tracksuit">
        <MailIcon className={styles.social} />
      </NavLink>
      <NavLink href={instagramLink} aria-label="Tracksuit on Instagram">
        <InstagramIcon className={styles.social} />
      </NavLink>
      <NavLink href={facebookLink} aria-label="Tracksuit on Facebook">
        <FacebookIcon className={styles.social} />
      </NavLink>
    </div>
  );
}
