"use client";

import { Button } from "$src/components/button/button";
import {
  CollapsibleTrigger,
  ControlledCollapsible
} from "$src/components/collapsible/collapsible";
import { NavLink } from "$src/components/nav-link/nav-link";
import { SocialIcons } from "$src/components/social-icons/social-icons";
import { resolveLink } from "$src/lib/sanity";
import { cx } from "@tracksuit/utils";
import { AnimatePresence, motion } from "framer-motion";
import { LogIn, Menu, X } from "lucide-react";
import { useEffect, useState, type ComponentProps } from "react";
import styles from "./mobile-header.module.css";

type MobileHeaderProps = {
  data: any;
  region: string;
  invertHeader: boolean;
} & ComponentProps<"div">;

export type NavProps = {
  /** Stage of the funnel */
  item: any;
  /** Whether stage is open */
  open: boolean;
  region: string;
  /** Callback when the collapsible closes */
  onToggleOpen: () => void;
  setOpen: (open: boolean) => void;
} & ComponentProps<"div">;

function NavCollapsible({
  item,
  open,
  region,
  onToggleOpen,
  setOpen
}: NavProps) {
  return (
    <div className={styles["nav-collapsible"]}>
      <CollapsibleTrigger open={open} onClick={() => onToggleOpen()}>
        <span className={styles.collapsible}>{item.label}</span>
      </CollapsibleTrigger>
      <ControlledCollapsible
        open={open}
        className={styles.dropdown}
        key={item.label}
      >
        {item?.links?.map((subItem: any) => (
          <NavLink
            key={subItem.label}
            onClick={() => setOpen(false)}
            className={cx(styles["collapsible-content"])}
            href={resolveLink(region, subItem.link)}
            activeClass={styles.active}
          >
            {subItem.label}
          </NavLink>
        ))}
      </ControlledCollapsible>
    </div>
  );
}

/**
 * @component
 * The mobile header houses navigation and branding for user-friendly
 * access to essential content on tablet and mobile.
 */
export function MobileHeader({
  invertHeader,
  data,
  region,
  className
}: MobileHeaderProps) {
  const [open, setOpen] = useState(false),
    [openCollapsible, setOpenCollapsible] = useState<string | null>(null);

  useEffect(() => {
    document.documentElement.style.overflow = open ? "hidden" : "";
  }, [open]);

  return (
    <div className={cx(styles.container, className)}>
      <div
        className={cx(styles.icon, open && styles.close)}
        onKeyDown={() => setOpen((o) => !o)}
        onClick={() => {
          setOpen((o) => !o);
        }}
      >
        {open ? (
          <X data-testid="close" />
        ) : (
          <Menu
            className={invertHeader ? styles["invert-header-icon"] : ""}
            data-testid="hamburger"
          />
        )}
      </div>
      <AnimatePresence>
        {open && (
          <motion.nav
            className={cx(styles["menu-container"])}
            data-testid="motion-div"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ stiffness: 200, damping: 20 }}
          >
            <div className={styles["menu-scroll-container"]}>
              {data?.headerNav?.map((item: any) =>
                item?._type === "dropdown" ? (
                  <NavCollapsible
                    region={region}
                    open={openCollapsible === item.label}
                    onToggleOpen={() =>
                      setOpenCollapsible(
                        openCollapsible === item.label ? null : item.label
                      )
                    }
                    key={item.label}
                    item={item}
                    {...{ setOpen }}
                  />
                ) : (
                  <NavLink
                    key={item.label}
                    onClick={() => setOpen(false)}
                    className={cx(styles.link)}
                    href={resolveLink(region, item.link)}
                    activeClass={styles.active}
                  >
                    {item.label}
                  </NavLink>
                )
              )}

              <NavLink
                className={cx(styles.link)}
                onClick={() => setOpen(false)}
                href={"https://app.gotracksuit.com/login"}
              >
                Login <LogIn />
              </NavLink>
              <div className={styles.button}>
                <Button
                  className={styles.focus}
                  label={"Request a demo"}
                  href={`/${region}/contact`}
                />
              </div>
              <SocialIcons
                className={styles["social-icons"]}
                instagramLink={data?.socials?.instagram}
                mailLink={data?.socials?.email}
                facebookLink={data?.socials?.facebook}
                linkedInLink={data?.socials?.linkedin}
              />
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
}
