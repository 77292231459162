"use client";

import { useHover } from "$src/hooks/useHover";
import { cx } from "@tracksuit/utils";
import { motion } from "framer-motion";
import { Lock } from "lucide-react";
import Link from "next/link";
import { useRef, type ComponentProps } from "react";
import styles from "./dropdown-item.module.css";

/**
 * @component
 * Highlighted dropdown items
 */
export function DropdownItem({
  id,
  disabled,
  locked,
  active,
  label,
  href,
  className,
  children,
  ...props
}: {
  id: string;
  disabled?: boolean;
  active?: boolean;
  locked?: boolean;
  label: string;
  href?: string;
} & ComponentProps<"div">) {
  const item = useRef<HTMLDivElement>(null),
    El = href ? Link : "div",
    isHovering = useHover(item);

  return (
    <El
      ref={item as any}
      href={href}
      className={cx(styles.item, className)}
      style={{ pointerEvents: disabled || locked ? "none" : "auto" }}
      {...(props as any)}
    >
      {isHovering && (
        <motion.div
          layout="position"
          layoutId={id}
          transition={{ duration: 0.075 }}
          className={styles.highlight}
        />
      )}
      <div
        className={cx(
          styles.content,
          locked && styles.locked,
          active && styles.active
        )}
      >
        <div className={styles["content-inner"]}>{label}</div>
        {locked && <Lock className={styles.icon} />}
      </div>
    </El>
  );
}
