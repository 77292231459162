import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

type HeaderStore = {
  inverted: boolean;
};

type UseHeader = HeaderStore & {
  set(props: HeaderStore): void;
};

export const useHeader = createWithEqualityFn<UseHeader>()(
  (set) => ({
    inverted: false,
    set: (props) => set(props)
  }),
  shallow
);
